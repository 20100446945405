import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Redirect from '../../components/redirect';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>

    <Redirect to='/advanced/api#sync-api' />

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      